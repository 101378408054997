<template>
    <div>
        <highcharts
            v-if="loaded"
            :constructorType="'mapChart'"
            :options="chartOptions"
            class="map"
            ref="chart"/>
        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import mapInit from 'highcharts/modules/map'
    import exportingInit from "highcharts/modules/exporting"
    import worldMap from '@/assets/custom-world.geo.json'
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    mapInit(Highcharts)
    exportingInit(Highcharts)

    export default {
        name: 'StartupsLocationChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart,
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsByCountry",
                chartData: [],
                chartOptions: {
                    chart: {
                        map: worldMap,
                    },
                    title: {
                        text: 'Startups By Location'
                    },
                    plotOptions: {
                        series: {
                            point: {
                                events: {
                                    click: (e) => {
                                        this.openStartupsModal(e.point.name, e.point['hc-key'])
                                    }
                                }
                            }
                        }
                    },
                    mapNavigation: {
                        enabled: true,
                        buttonOptions: {
                            alignTo: 'spacingBox'
                        }
                    },
                    colorAxis: {
                        min: 0,
                        minColor: '#efecf3',
                        maxColor: '#0083b2'
                    },
                    series: [{
                        name: 'Startups',
                        states: {
                            hover: {
                                color: '#043648'
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            useHTML: true,
                            format: '{point.name}: {point.value:,.0f}',
                            filter: {
                                operator: '>',
                                property: 'value',
                                value: 1,
                            },
                            style: {
                                textAlign: 'center',
                                textOverflow: 'clip',
                                width: 80,
                                zIndex: 0
                            }
                        },
                        allAreas: true,
                        data: []
                    }]
                }
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('StartupsByCountry').then(response => {
                    this.chartData = response.data.map(({code, cnt}) => ([code.toLowerCase(), cnt]))
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },

            formatAndSetChartData() {
                this.chartOptions.series[0].data = Array.from(this.chartData)

                // this.$refs.chart.chart.series[0].setData(Array.from(this.chartData), true)
            },

            openStartupsModal(countryName, countryCode) {
                this.$emit('showStartups', [this.chartName, "Startups in " + countryName, countryCode])
            },
        }
    }
</script>
